import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import "./TOEFL1.css";
import "../../components/Navbar.css";
import Logo from "../../image/logo.png";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const Toefl1 = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="navbar">
        <div className="menu-bar">
          <ArrowBackIosNewIcon
            fontSize="large"
            style={{ color: "#fff" }}
            onClick={() => {
              navigate("/user/TOEFL");
            }}
          />
        </div>
        <img className="Logo" src={Logo} />
      </div>
      <div>{<Outlet />}</div>
    </>
  );
};

export default Toefl1;
