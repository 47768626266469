import React from 'react';
import "./Error.css"

export default function notmatch() {
    return(
        <div id="notfound">
            <div className="notfound">
                <div>
                    <div className="notfound-404">
                        <h1>!</h1>
                    </div>
                </div>
                <h2>This page is not available</h2>
                <p>Please check the URL is valid and try again.</p>
            </div>
        </div>
    )
}