import http from "./http-common";

class AdminService {
  send_email(email) {
    return http.post("/admin/create_user", email);
  }
  get_all_user() {
    return http.get("/admin/get_students");
  }
  change_active(data) {
    return http.put("/admin/active", data);
  }
  upload_file(data) {
    return http.post("/admin/upload_file", data);
  }
  delete_user(data) {
    return http.put("/admin/delete_user", data);
  }
  submit_toefl(data) {
    return http.post("/admin/submit_toefl", data);
  }
  get_saved_exam(data) {
    return http.post("/admin/get_saved_exam", data);
  }
  get_checking_table(data) {
    return http.post("/admin/get_checking_table", data);
  }
  check_essay(data) {
    return http.post("/admin/check_essay", data);
  }
  check_speaking(data) {
    return http.post("/admin/check_speaking", data);
  }
  check_reading(data) {
    return http.post("/admin/check_reading", data);
  }
  check_listening(data) {
    return http.post("/admin/check_listening", data);
  }
  save_score(data) {
    return http.post("/admin/save_score", data);
  }
  get_score(data) {
    return http.post("/admin/get_score", data);
  }
}
export default new AdminService();
