import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import "../components/Navbar.css";
import Logo from "../image/logo.png";
import Notmatchpage from "../components/notmatch";
import Footer from "./footer";
function Nomatch() {
  return (
    <div>
      <div>
        <div className="navbar">
          <img className="Logo" src={Logo} />
        </div>
      </div>
      <Notmatchpage />
      <Footer />
    </div>
  );
}
export default function RequireAuth({ allowedRoles }) {
  const { auth } = useAuth();
  const location = useLocation();
  const localdata = JSON.parse(localStorage.getItem("logged_in"));
  //if user not logged_in localdata will be null
  if (!auth.active)
    return <Navigate to="/" state={{ from: location }} replace />;

  if (
    auth?.role?.find((r) => allowedRoles?.includes(r)) &&
    localdata !== null
  ) {
    return <Outlet />;
  } else if (localdata !== null) {
    return <Nomatch />;
  } else {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  // return auth?.role?.find((r) => allowedRoles?.includes(r)) ? (
  //   <Outlet />
  // ) : (
  //   <Navigate to="/" state={{ from: location }} replace />
  // );
}
