import { Button, Checkbox, FormControlLabel } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../../components/footer";
import "../TOEFL1.css";

function TestInstruction(props) {
  const navigate = useNavigate();
  const [Valid, setValid] = useState(false);

  const Changevalid = () => {
    setValid(!Valid);
  };
  const onSubmit = () => {
    localStorage.setItem(
      "accept_test",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("accept_test")),
        [`toefl${props.toefl}`]: true,
      })
    );
    navigate(`/user/TOEFL${props.toefl}/selectsection`, { replace: true });
  };
  useEffect(() => {
    let accept = JSON.parse(localStorage.getItem("accept_test"));
    let empty = true;
    for (let i in accept) {
      empty = false;
      break;
    }
    if (accept && !empty) {
      if (accept[`toefl${props.toefl}`]) {
        navigate(`/user/TOEFL${props.toefl}/selectsection`, { replace: true });
      }
    }
  }, []);
  return (
    <div
      style={{
        padding: "50px",
        width: "100%",
        height: "90vh",
      }}
    >
      <h2 style={{ marginBottom: "20px", textAlign: "center" }}>
        General Test Information
      </h2>
      <div style={{ height: "75vh" }}>
        <div className="Instruction">
          <p>
              <span>This test measures your ability to use English in an academic context. There are 4 sections.</span>
          </p>
            <br></br>
          <p>
              <span>In the&nbsp;</span>
              <strong>Reading</strong>
              <span>section, you will read several passages and answer questions about them.</span>
          </p>
          <p>
              <span>In the&nbsp;</span>
              <strong>Listening</strong>
              <span>&nbsp;section, you will hear several conversations and lectures and answer questions about them.</span>
          </p>
          <p>
              <span>In the&nbsp;</span>
              <strong>Speaking</strong>
              <span>&nbsp;section, you will answer 4 questions. Some of the questions ask you to speak about your own experience. Other questions ask you to speak about lectures and reading passages.</span>
          </p>
          <p>
              <span>In the</span>
              <strong>&nbsp;Writing</strong>
              <span>&nbsp;section you will answer 2 questions. The first question asks you to write about the relationship between a lecture you will hear and a passage you will read. The second questions ask you to write an essay about a topic of general based on your
                  experience.</span>
          </p>
            <br></br>
          <p>
              <span>There will be directions for each section which explain how to answer the questions in that section.</span>
          </p>
            <br></br>
          <p>
              <span>You should work quickly but carefully on the Reading and Listening questions. Some questions are more difficult than others but try to answer everyone to the best of your ability. If you are not sure of the answer to a question, make the best guess
                  that you can. The questions that you answer by speaking and writing are each separately timed. Try to answer every one of these as completely as possible in the time allowed.</span>
          </p>
        </div>
        <div style={{ textAlign: "center" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={Valid}
                onChange={() => Changevalid()}
                color="success"
              />
            }
            label="Click on continue to go on."
          />
          <div>
            <Button
              disabled={!Valid}
              variant="contained"
              color="error"
              onClick={onSubmit}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TestInstruction;
