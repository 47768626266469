import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import "./direction.css";

function WritingDirection(props) {
  return (
    <div className="directionpage">
      <h2 style={{ marginBottom: "40px" }}>Writing Section Directions</h2>
      <div className="direction_detail">
        <p>
          For this task, you will read a passage about an academic topic and you
          will listen to a lecture
        </p>
        <p>
          about the same topic. You may take notes while you read and listen.
        </p>
        <p>&nbsp;</p>
        <p>
          Then you will write a response to a question that asks you about the
          relationship between
        </p>
        <p>
          the lecture you heard and the reading passage. Try to answer the
          question as completely
        </p>
        <p>
          as possible using information from the reading passage and the
          lecture. The question does
        </p>
        <p>
          not ask you to express your personal opinion. You may use your notes
          to help you answer
        </p>
        <p>the question.</p>
        <p>&nbsp;</p>
        <p>
          Typically, an effective response will be 150 to 225 words. Your
          response will be judged on
        </p>
        <p>
          the quality of your writing and on the completeness and accuracy of
          the content.
        </p>
        <p>Give yourself 3 minutes to read the passage.</p>
      </div>
      <Link
        style={{ textDecoration: "none" }}
        to={`/user/TOEFL${props.toefl}/Writing`}
        replace={props.from_submit}
      >
        <Button variant="contained" color="error">
          Start test
        </Button>
      </Link>
    </div>
  );
}

export default WritingDirection;
