import React, { useState, useEffect } from "react";
import "./login.css";
import userService from "../../services/user-service";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import Logo from "../../image/logo.png";

import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Button, TextField, Link, Box } from "@mui/material";
import Footer from "../../components/footer";
import Swal from "sweetalert2";

export default function Login() {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const [Uname, setUname] = useState("");
  const [Pwd, setPwd] = useState("");

  useEffect(() => {
    const localdata = JSON.parse(localStorage.getItem("logged_in"));
    if (localdata && typeof localdata != "undefined") {
      if (localdata.status) {
        userService
          .check_login()
          .then((res) => {
            res.data.active &&
              localStorage.setItem(
                "logged_in",
                JSON.stringify({
                  status: true,
                  // role: res.data.role,
                  expiry: new Date().getTime() + (6 + 7) * 3600000, //localstorage expire in 6 hr
                })
              );
            setAuth({ role: [res.data.role], active: res.data.active });
            if (res.data.role === "user") {
              navigate("/user/Profile", { replace: true });
            } else if (res.data.role === "admin") {
              navigate("/admin/Student", { replace: true });
            }
          })
          .catch((err) => {
            localStorage.clear();
          });
      }
    }
  }, []);

  const submit = async (e) => {
    e.preventDefault();
    await userService
      .login({ email: Uname, pwd: Pwd })
      .then((res) => {
        res.data.active &&
          localStorage.setItem(
            "logged_in",
            JSON.stringify({
              status: true,
              // role: res.data.role,
              expiry: new Date().getTime() + (6 + 7) * 3600000, //localstorage expire in 6 hr
            })
          );
        setAuth({ role: [res.data.role], active: res.data.active });
        if (res.data.role === "user") {
          navigate("/user/Profile", { replace: true });
        } else if (res.data.role === "admin") {
          navigate("/admin/Student", { replace: true });
        }
      })
      .catch((err) => {
        // console.log(err);
        if (
          err.response.status === 503 &&
          err.response.data.message === "This account is not active!"
        ) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: err.response.data.message,
          });
          navigate("/");
          return;
        }
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Invalid Username or Password!",
        });
        navigate("/");
      });
  };

  const forget_pwd = () => {
    Swal.fire({ icon: "warning", text: "Please contact your proctor." });
  };

  return (
    <div>
      <div className="login-form-container">
        <img className="login-logo" src={Logo} />
        <Box
          className="login-container"
          component="form"
          onSubmit={(e) => {
            submit(e);
          }}
        >
          <Avatar
            sx={{ margin: "auto", bgcolor: "#d32f2f", width: 50, height: 50 }}
          >
            <LockOutlinedIcon sx={{ width: 30, height: 30 }} />
          </Avatar>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Username (E-mail)"
            autoFocus
            onChange={(e) => setUname(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Password"
            type="password"
            onChange={(e) => setPwd(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="error"
            sx={{
              mt: 3,
              mb: 1,
              height: 50,
              fontSize: "larger",
              fontWeight: "bold",
            }}
          >
            Log In
          </Button>
          <div style={{ display: "inline-flex", marginBottom: "6px" }}>
            {" "}
            <div style={{ textAlign: "left", width: "50%" }}>
              <Link
                href="#"
                variant="body2"
                style={{ fontSize: "16px" }}
                onClick={forget_pwd}
              >
                Forget password?
              </Link>
            </div>
            <div style={{ textAlign: "right", width: "50%" }}>
              <Link
                href="/register"
                variant="body2"
                style={{ fontSize: "16px" }}
              >
                Create an account
              </Link>
            </div>
          </div>
        </Box>

        <Footer />
      </div>
    </div>
  );
}
