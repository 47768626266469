import http from "./http-common";

class UserService {
  login(data) {
    return http.post("/user/login", data);
  }
  logout() {
    return http.post("/user/logout");
  }
  check_login() {
    return http.get("/user/check_login");
  }
  // check_url(data) {
  //   return http.post("/user/register/check_url", data);
  // }
  register(data) {
    return http.post("/user/register", data);
  }
  // delete_url(data) {
  //   return http.put("/user/delete_url", data);
  // }
  getdata() {
    return http.get("/user/getdata");
  }
  getexam(data) {
    return http.post("/user/getexam", data);
  }
  submit_exam(data) {
    return http.post("/user/submit", data);
  }
  get_exam_status(data) {
    return http.post("/user/get_exam_status", data);
  }
  get_summary() {
    return http.get("/user/get_summary");
  }
  // check_exam_status(data) {
  //   return http.post("/user/check_exam_status", data);
  // }
}
export default new UserService();
