import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import userService from "../../services/user-service";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Logo from "../../image/logo.png";
import Footer from "../../components/footer";
import "./register.css";
import {
  Grid,
  TextField,
  Box,
  Button,
  Typography,
  Input,
  Link,
} from "@mui/material";

export default function Register() {
  const navigate = useNavigate();

  const [fname, setFname] = useState();
  const [lname, setLname] = useState();
  const [nname, setNname] = useState();
  const [tel, setTel] = useState();
  const [email, setEmail] = useState();
  const [university, setUniversity] = useState();
  const [program, setProgram] = useState();
  const [gpa, setGpa] = useState();
  const [pwd, setPwd] = useState();
  const [matchpwd, setMatchpwd] = useState(true);
  const { id } = useParams();
  const onSubmit = (e) => {
    e.preventDefault();
    if (matchpwd) {
      Swal.fire({
        html: `<p style="text-align:left;">MBATHINKTANK and MANHATTANTEST may collect, retain, or disclose your Personal Data or any information by the individual for, including but not limited to, the effectiveness of services, profile analysis, communication and marketing purposes, feedback providing, counselling, recommendation, and the collected, retained or disclosed information shall comply with PDPA 2019.</p><br>
        <p style="text-align:left;"><em>By clicking the submit button below, I hereby accept, agree and consent to the terms and conditions above.</em></p>`,
        icon: "info",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Submit",
      }).then((result) => {
        if (result.isConfirmed) {
          userService
            .register({
              fname: fname,
              lname: lname,
              nname: nname,
              tel: tel,
              email: email,
              university: university,
              program: program,
              gpa: gpa,
              pwd: pwd,
            })
            .then((res) => {
              Swal.fire({
                icon: "success",
                text: "Congratulations!! your account has been successfully created.",
              });
              navigate("/", { replace: true });
              //console.log(res)}
            })
            .catch((err) => {
              if (err.response.status === 409) {
                Swal.fire({
                  icon: "error",
                  text: "User already exist. Please login",
                });
              } else {
                Swal.fire({
                  icon: "error",
                  text: "Registration fail, please try again.",
                });
              }
              //console.log(err);
            });
        }
      });
    }
  };
  const pwdValidate = (confirm_inp) => {
    if (pwd !== confirm_inp) {
      setMatchpwd(false);
    } else {
      setMatchpwd(true);
    }
  };
  return (
    <div
      style={{
        margin: 0,
        width: "100vw",
        minHeight: "100vh",
        overflowY: "auto",
        display: "flex",
      }}
    >
      <div className="form-container">
        <img className="register-logo" src={Logo} />

        <div className="register-container">
          <Box component="form" onSubmit={(e) => onSubmit(e)}>
            <Typography
              component="h1"
              variant="h5"
              sx={{
                mt: 2,
                mb: 2,
                height: 25,
                fontSize: "larger",
                fontWeight: "bold",
              }}
            >
              Registration Form
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="First Name"
                  inputProps={{ maxLength: 50 }}
                  onChange={(e) => {
                    setFname(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Last Name"
                  inputProps={{ maxLength: 50 }}
                  onChange={(e) => {
                    setLname(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Nickname"
                  inputProps={{ maxLength: 50 }}
                  onChange={(e) => {
                    setNname(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Telephone Number"
                  type="tel"
                  inputProps={{ pattern: "[0-9]{10}", max: 10 }}
                  onChange={(e) => {
                    setTel(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Email"
                  type="email"
                  inputProps={{ maxLength: 100 }}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="GPA (Ex. 3.90)"
                  inputProps={{ pattern: "^[0-3].([0-99]{2})$|^4.([0]{2})$" }}
                  onChange={(e) => {
                    setGpa(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Name of Past/Current University Attended (Undergrad)"
                  inputProps={{ maxLength: 100 }}
                  onChange={(e) => {
                    setUniversity(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Name of Program or Faculty Attended"
                  inputProps={{ maxLength: 100 }}
                  onChange={(e) => {
                    setProgram(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Password"
                  type="password"
                  inputProps={{ minLength: 8, maxLength: 50 }}
                  onChange={(e) => {
                    setPwd(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Confirm password"
                  type="password"
                  inputProps={{ minLength: 8, maxLength: 50 }}
                  onChange={(e) => {
                    pwdValidate(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <p
              style={{
                fontWeight: "bold",
                color: "red",
                marginTop: "10px",
                paddingTop: "5px",
              }}
            >
              {matchpwd ? "" : "Password and Confirm Password does not match."}
            </p>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="error"
              sx={{
                mt: 3,
                mb: 2,
                height: 50,
                fontSize: "larger",
                fontWeight: "bold",
              }}
            >
              Submit
            </Button>
            <div style={{ marginBottom: "6px" }}>
              {"Already have an account? "}
              <Link href="/" variant="body2" style={{ fontSize: "16px" }}>
                Log in
              </Link>
            </div>
          </Box>
        </div>
        <Footer />
      </div>
    </div>
  );
}
