import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import "./direction.css";
import picture from "../../../../image/listening.jpg"

function ListeningDirection(props) {
  return (
    <div className="directionpage">
      <h2 style={{ marginBottom: "40px" }}>Listening Section Direction</h2>
      <div className="direction_detail">
        <p>This section measures your ability to understand conversations and lectures in English.</p>
        <p>You should listen to each conversation and lecture only once.</p>
        <p>&nbsp;</p>
        <p>After each conversation or lecture, you will answer some questions about it. The questions</p>
        <p>typically ask about the main idea and supporting details. Some questions ask about the</p>
        <p>purpose of a speaker’s statement or a speaker’s attitude. Answer the questions based on</p>
        <p>what is stated or implied by the speakers.</p>
        <p>&nbsp;</p>
        <p>You may take notes while you listen. You may use your notes to help you answer the questions. Your notes will not be scored.</p>
        <p>
            <strong>&nbsp;</strong>
        </p>
        <p>
          Most questions are worth 1 point. If a question is worth more than 1 point, it will have special directions that indicate how many points you can receive. It will take about 46 minutes to listen to the conversations and lectures and to answer the questions.&nbsp;
        </p>
      </div>
      <Link
        style={{ textDecoration: 'none' }}
        to={`/user/TOEFL${props.toefl}/Listening`}
        replace={props.from_submit}
      >
        <Button variant="contained" color="error">
          Start test
        </Button>
      </Link>
    </div>
  );
}

export default ListeningDirection;
