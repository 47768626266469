import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import "./direction.css";

function SpeakingDirection(props) {
  return (
    <div className="directionpage">
      <h2 style={{ marginBottom: "40px" }}>Speaking Section Directions</h2>
      <div className="direction_detail">
        <p>The following Speaking section of the test will last approximately 17 minutes.</p>
        <p>&nbsp;</p>
        <p>During the test, you will answer 4 speaking questions. One question asks about a familiar topic.</p>
        <p>Three questions ask about short conversations, lectures, and reading passages.</p>
        <p>&nbsp;</p>
        <p>You may take notes as you listen to the conversations and lectures. The questions and the</p>
        <p>reading passages are printed here. The time you will have to prepare your response and to</p>
        <p>speak is printed below each question. You should answer all of the questions as completely</p>
        <p>as possible in the time allowed.</p>
        <p>&nbsp;</p>
        <p>Listen carefully to the directions for each question. The directions will not be written on the screen. For each question, you will be given a short time to prepare your responses (15 to 30 seconds, depending on the question.) A clock will show how much
            preparation time is remaining. When the preparation time is up, you will be told to begin your response. A clock will show how much response time is remaining. A message will appear on the screen when the response time has ended.
        </p>
      </div>
      <Link
        style={{ textDecoration: 'none' }}
        to={`/user/TOEFL${props.toefl}/Speaking`}
        replace={props.from_submit}
      >
        <Button variant="contained" color="error">
          Start test
        </Button>
      </Link>
    </div>
  );
}

export default SpeakingDirection;
