import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import UserPage from "./pages/user/user";
import TestInstruction from "./pages/TOEFL1/component/testinstruction";
import TOEFL1 from "./pages/TOEFL1/TOEFL1";
import SelectPart from "./pages/TOEFL1/component/selectpart";
import "./App.css";
import { useNavigate } from "react-router-dom";

const LazyUserPage = React.lazy(() => import("./pages/user/user"));
const LazyReading = React.lazy(() =>
  import("./pages/TOEFL1/component/exam/reading")
);
const LazyWriting = React.lazy(() =>
  import("./pages/TOEFL1/component/exam/writing")
);
const LazyListening = React.lazy(() =>
  import("./pages/TOEFL1/component/exam/listening")
);
const LazySpeaking = React.lazy(() =>
  import("./pages/TOEFL1/component/exam/speaking")
);
function UserRoute() {
  const navigate = useNavigate();
  return (
    <>
      <div className="App">
        <Routes>
          <Route
            path="/*"
            element={
              <React.Suspense fallback="Loading...">
                <LazyUserPage />
              </React.Suspense>
            }
          />
          {/* TOEFL1 */}
          <Route path="TOEFL1" element={<TOEFL1 />}>
            <Route path="TestInfo" element={<TestInstruction toefl={1} />} />
            <Route
              path="selectsection"
              element={<SelectPart toefl={1} />}
            ></Route>
          </Route>
          <Route
            path="TOEFL1/Reading"
            element={
              <React.Suspense fallback="Loading...">
                <LazyReading
                  toefl={1}
                  navigate={navigate}
                  from={"/user/TOEFL1/selectsection"}
                />
              </React.Suspense>
            }
          />
          <Route
            path="TOEFL1/Listening"
            element={
              <React.Suspense fallback="Loading...">
                <LazyListening
                  toefl={1}
                  navigate={navigate}
                  from={"/user/TOEFL1/selectsection"}
                />
              </React.Suspense>
            }
          />
          <Route
            path="TOEFL1/Speaking"
            element={
              <React.Suspense fallback="Loading...">
                <LazySpeaking
                  toefl={1}
                  navigate={navigate}
                  from={"/user/TOEFL1/selectsection"}
                />
              </React.Suspense>
            }
          />
          <Route
            path="TOEFL1/Writing"
            element={
              <React.Suspense fallback="Loading...">
                <LazyWriting
                  toefl={1}
                  navigate={navigate}
                  from={"/user/TOEFL1/selectsection"}
                />
              </React.Suspense>
            }
          />
          {/* TOEFL2 */}
          <Route path="TOEFL2" element={<TOEFL1 />}>
            <Route path="TestInfo" element={<TestInstruction toefl={2} />} />
            <Route
              path="selectsection"
              element={<SelectPart toefl={2} />}
            ></Route>
          </Route>
          <Route
            path="TOEFL2/Reading"
            element={
              <React.Suspense fallback="Loading...">
                <LazyReading
                  toefl={2}
                  navigate={navigate}
                  from={"/user/TOEFL2/selectsection"}
                />
              </React.Suspense>
            }
          />
          <Route
            path="TOEFL2/Listening"
            element={
              <React.Suspense fallback="Loading...">
                <LazyListening
                  toefl={2}
                  navigate={navigate}
                  from={"/user/TOEFL2/selectsection"}
                />
              </React.Suspense>
            }
          />
          <Route
            path="TOEFL2/Speaking"
            element={
              <React.Suspense fallback="Loading...">
                <LazySpeaking
                  toefl={2}
                  navigate={navigate}
                  from={"/user/TOEFL2/selectsection"}
                />
              </React.Suspense>
            }
          />
          <Route
            path="TOEFL2/Writing"
            element={
              <React.Suspense fallback="Loading...">
                <LazyWriting
                  toefl={2}
                  navigate={navigate}
                  from={"/user/TOEFL2/selectsection"}
                />
              </React.Suspense>
            }
          />
        </Routes>
      </div>
    </>
  );
}

export default UserRoute;
