import React, { useState, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  Outlet,
} from "react-router-dom";
import Login from "./pages/login/login";
import userService from "./services/user-service";
import adminService from "./services/admin-service";
import { AuthProvider } from "./context/AuthProvider";
import RequireAuth from "./components/RequireAuth";
import Register from "./pages/register/register";
import PersistLogin from "./components/PersistLogin";
import AdminRoute from "./AdminRoute";
import UserRoute from "./UserRoute";
import "./components/Navbar.css";
import Logo from "./image/logo.png";
import Notmatchpage from "./components/notmatch";
import Footer from "./components/footer";
function Nomatch() {
  return (
    <div>
      <div>
        <div className="navbar">
          <img className="Logo" src={Logo} />
        </div>
      </div>
      <Notmatchpage />
      <Footer />
    </div>
  );
}
function App() {
  useEffect(() => {
    const localdata = JSON.parse(localStorage.getItem("logged_in"));
    if (localdata) {
      if (new Date().getTime() + 7 * 3600000 > localdata.expiry) {
        //clear local storage and cookie
        localStorage.clear();
        userService.logout().catch((err) => {
          //console.log(err);
        });
      }
    }
  }, []);

  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <main>
                <Outlet />
              </main>
            }
          >
            {/* public route */}
            <Route path="/" element={<Login />} />
            <Route path="register" element={<Register />} />
            {/* Protected route */}
            <Route element={<PersistLogin />}>
              <Route element={<RequireAuth allowedRoles={["user"]} />}>
                <Route path="user/*" element={<UserRoute />} />
              </Route>

              <Route element={<RequireAuth allowedRoles={["admin"]} />}>
                <Route path="admin/*" element={<AdminRoute />} />
              </Route>
            </Route>
          </Route>
          <Route path="*" element={<Nomatch />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
