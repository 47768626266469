import React from "react";
import { Routes, Route } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./App.css";

const LazyAdminPage = React.lazy(() => import("./pages/admin/admin"));
const LazyAdminTOEFL1Page = React.lazy(() =>
  import("./pages/admin/components/TOEFL1/adminTOEFL1")
);
const LazyReading = React.lazy(() =>
  import("./pages/admin/components/TOEFL1/component/exam/reading")
);
const LazyLisstening = React.lazy(() =>
  import("./pages/admin/components/TOEFL1/component/exam/listening")
);
const LazySpeaking = React.lazy(() =>
  import("./pages/admin/components/TOEFL1/component/exam/speaking")
);
const LazyWriting = React.lazy(() =>
  import("./pages/admin/components/TOEFL1/component/exam/writing")
);
const LazyAdminEssayPage = React.lazy(() =>
  import("./pages/admin/components/essay")
);
const LazyAdminSpeakingPage = React.lazy(() =>
  import("./pages/admin/components/speaking")
);
const LazyAdminReadingPage = React.lazy(() =>
  import("./pages/admin/components/reading")
);
const LazyAdminListeningPage = React.lazy(() =>
  import("./pages/admin/components/listening")
);
const LazyCheckEssay = React.lazy(() =>
  import("./pages/admin/components/checkEssayPage")
);
const LazyCheckSpeaking = React.lazy(() =>
  import("./pages/admin/components/checkSpeakingPage")
);
const LazyCheckReading = React.lazy(() =>
  import("./pages/admin/components/checkReadingPage")
);
const LazyCheckListening = React.lazy(() =>
  import("./pages/admin/components/checkListeningPage")
);
function AdminRoute() {
  const navigate = useNavigate();
  return (
    <>
      <div className="App">
        <Routes>
          <Route
            path="/*"
            element={
              <React.Suspense fallback="Loading...">
                <LazyAdminPage />
              </React.Suspense>
            }
          />

          {/*Toefl1*/}
          <Route
            path="/AdminTOEFL1"
            element={
              <React.Suspense fallback="Loading...">
                <LazyAdminTOEFL1Page toefl={1} navigate={navigate} />
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/AdminTOEFL1/adminreading"
            element={
              <React.Suspense fallback="Loading...">
                <LazyReading
                  toefl={1}
                  from={"/admin/AdminTOEFL1"}
                  navigate={navigate}
                />
              </React.Suspense>
            }
          />
          <Route
            path="/AdminTOEFL1/adminlistening"
            element={
              <React.Suspense fallback="Loading...">
                <LazyLisstening
                  toefl={1}
                  from={"/admin/AdminTOEFL1"}
                  navigate={navigate}
                />
              </React.Suspense>
            }
          />
          <Route
            path="/AdminTOEFL1/adminwriting"
            element={
              <React.Suspense fallback="Loading...">
                <LazyWriting
                  toefl={1}
                  from={"/admin/AdminTOEFL1"}
                  navigate={navigate}
                />
              </React.Suspense>
            }
          />
          <Route
            path="/AdminTOEFL1/adminspeaking"
            element={
              <React.Suspense fallback="Loading...">
                <LazySpeaking
                  toefl={1}
                  from={"/admin/AdminTOEFL1"}
                  navigate={navigate}
                />
              </React.Suspense>
            }
          />
          {/*Toefl2*/}
          <Route
            path="/AdminTOEFL2"
            element={
              <React.Suspense fallback="Loading...">
                <LazyAdminTOEFL1Page toefl={2} navigate={navigate} />
              </React.Suspense>
            }
          ></Route>
          <Route
            path="/AdminTOEFL2/adminreading"
            element={
              <React.Suspense fallback="Loading...">
                <LazyReading
                  toefl={2}
                  from={"/admin/AdminTOEFL2"}
                  navigate={navigate}
                />
              </React.Suspense>
            }
          />
          <Route
            path="/AdminTOEFL2/adminlistening"
            element={
              <React.Suspense fallback="Loading...">
                <LazyLisstening
                  toefl={2}
                  from={"/admin/AdminTOEFL2"}
                  navigate={navigate}
                />
              </React.Suspense>
            }
          />
          <Route
            path="/AdminTOEFL2/adminwriting"
            element={
              <React.Suspense fallback="Loading...">
                <LazyWriting
                  toefl={2}
                  from={"/admin/AdminTOEFL2"}
                  navigate={navigate}
                />
              </React.Suspense>
            }
          />
          <Route
            path="/AdminTOEFL2/adminspeaking"
            element={
              <React.Suspense fallback="Loading...">
                <LazySpeaking
                  toefl={2}
                  from={"/admin/AdminTOEFL2"}
                  navigate={navigate}
                />
              </React.Suspense>
            }
          />

          {/* checkingPages */}
          <Route
            path="/Writing/:toefl"
            element={
              <React.Suspense fallback="Loading">
                <LazyAdminEssayPage />
              </React.Suspense>
            }
          />
          <Route
            path="/Writing/:toefl/:id"
            element={
              <React.Suspense fallback="Loading">
                <LazyCheckEssay />
              </React.Suspense>
            }
          />
          <Route
            path="/Speaking/:toefl"
            element={
              <React.Suspense fallback="Loading">
                <LazyAdminSpeakingPage />
              </React.Suspense>
            }
          />
          <Route
            path="/Speaking/:toefl/:id"
            element={
              <React.Suspense fallback="Loading">
                <LazyCheckSpeaking />
              </React.Suspense>
            }
          />

          <Route
            path="/Reading/:toefl"
            element={
              <React.Suspense fallback="Loading">
                <LazyAdminReadingPage />
              </React.Suspense>
            }
          />
          <Route
            path="/Reading/:toefl/:id"
            element={
              <React.Suspense fallback="Loading">
                <LazyCheckReading />
              </React.Suspense>
            }
          />
          <Route
            path="/Listening/:toefl"
            element={
              <React.Suspense fallback="Loading">
                <LazyAdminListeningPage />
              </React.Suspense>
            }
          />
          <Route
            path="/Listening/:toefl/:id"
            element={
              <React.Suspense fallback="Loading">
                <LazyCheckListening />
              </React.Suspense>
            }
          />
        </Routes>
      </div>
    </>
  );
}

export default AdminRoute;
