import { Outlet, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import userService from "../services/user-service";
const PersistLogin = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { auth, setAuth } = useAuth();
  useEffect(() => {
    if (typeof auth.role === "undefined") {
      setIsLoading(true);
      userService
        .check_login()
        .then((res) => {
          setAuth({ role: [res.data.role], active: res.data.active });
        })
        .catch((err) => {
          localStorage.clear();
          navigate("/", { replace: true });
        });
    } else {
      setIsLoading(false);
    }
  }, [auth]);

  return <>{isLoading ? <p>Loading...</p> : <Outlet />}</>;
};
export default PersistLogin;
