import { Button, Stack } from "@mui/material";
import React, { useState, useEffect } from "react";
import ReadingDirections from "./direction/readingdirection";
import ListeningDirections from "./direction/listeningdirection";
import SpeakingDirections from "./direction/speakingdirection";
import WritingDirections from "./direction/writingdirection";
import "../../../components/divider.css";
import "./select.css";
import userService from "../../../services/user-service";
import { useNavigate, useLocation } from "react-router-dom";
function SelectPart(props) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [PageSelect, setPageSelect] = useState("");
  const [Disabled, setDisabled] = useState(null);
  const [LoadingData, setLoadingData] = useState(true);

  const partButton = [
    {
      id: "Reading",
      status: "Incomplete",
    },
    {
      id: "Listening",
      status: "Incomplete",
    },
    {
      id: "Speaking",
      status: "Incomplete",
    },
    {
      id: "Writing",
      status: "Incomplete",
    },
  ];

  const renderSwitch = () => {
    switch (PageSelect) {
      case "Reading":
        return (
          <ReadingDirections
            from_submit={state === "from_submit"}
            toefl={props.toefl}
          />
        );
      case "Listening":
        return (
          <ListeningDirections
            from_submit={state === "from_submit"}
            toefl={props.toefl}
          />
        );
      case "Speaking":
        return (
          <SpeakingDirections
            from_submit={state === "from_submit"}
            toefl={props.toefl}
          />
        );
      case "Writing":
        return (
          <WritingDirections
            from_submit={state === "from_submit"}
            toefl={props.toefl}
          />
        );
      default:
        return <h3>Please select the section</h3>;
    }
  };
  useEffect(() => {
    if (state == "from_submit") {
      navigate(-1, { replace: true });
    }
    userService
      .get_exam_status({ toefl: props.toefl })
      .then((res) => {
        //console.log(res.data);
        setDisabled(res.data);
      })
      .catch((err) => {
        if (
          err.response.status === 401 &&
          err.response.data.message === "No token"
        ) {
          localStorage.clear();
          navigate("/", { replace: true });
        }
        //console.log(err);
      });
  }, []);
  useEffect(() => {
    if (Disabled !== null) {
      setLoadingData(false);
    }
  }, [Disabled]);
  if (LoadingData) return <p>Loading...</p>;
  return (
    <div>
      <div className="splitLeft">
        <h2 style={{ marginBottom: "40px" }}>TOEFL Test Section</h2>
        <Stack spacing={2} justifyContent={"center"}>
          {partButton.map((part, key) => {
            return (
              <Button
                disabled={Disabled[part.id.toLowerCase() + props.toefl]}
                key={"btn" + key}
                variant="contained"
                color="error"
                onClick={() => {
                  setPageSelect(part.id);
                }}
              >
                {part.id}
              </Button>
            );
          })}
        </Stack>
      </div>
      <div className="splitRight">{renderSwitch()}</div>
    </div>
  );
}

export default SelectPart;
