import React from "react";
import "./footer.css";

export default function footer() {
  return (
    <div className="footer-container">
      <p>Copyright &copy; by Manhattan Test</p>
    </div>
  );
}
