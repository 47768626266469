import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import "./direction.css";

function ReadingDirection(props) {
  return (
    <div className="directionpage">
      <h2 style={{ marginBottom: "40px" }}>Reading Section Directions</h2>
      <div className="direction_detail">
        <p>This section measures your ability to understand academic passages in English. You will read&nbsp;
            <strong>3 passages</strong>&nbsp;and have&nbsp;
            <strong>54 minutes</strong>&nbsp;to read all the passages and answer all the questions.</p>
        <p>&nbsp;</p>
        <p>Within this section, you can go to the next question by clicking
            <strong>Next</strong>. You may skip questions in the current passage and go back to them later.</p>
        <p>&nbsp;</p>
        <p>You can click on&nbsp;
            <strong>Back</strong>&nbsp;at any time and review which questions you have answered and which you have not answered.&nbsp;
        </p>
      </div>
      <Link
        style={{ textDecoration: 'none' }}
        to={`/user/TOEFL${props.toefl}/Reading`}
        replace={props.from_submit}
      >
        <Button variant="contained" color="error">
          Start test
        </Button>
      </Link>
    </div>
  );
}

export default ReadingDirection;
